@import "../../styles/vars.scss";
@import "../../styles/mixins.scss";

.activities-details-container {

    // flex: 1;
    //    @include flex-column;

    .title {
        font-size: 20px;
        font-weight: 700;
        font-family: 'DIN bold';
        line-height: 29.26px;
        color: $primary;
    }

    .title2 {
        font-size: 16px;
        font-weight: 700;
        font-family: 'DIN';
        line-height: 29.26px;
        color: '#1A2945';
    }

    .title3 {
        font-size: 18px;
        font-weight: 500;
        font-family: 'DIN';
        line-height: 29.26px;
        color: '#1A2945';
    }

    .content {
        font-size: 10px;
        font-weight: 400;
        font-family: 'DIN';
        // line-height: 18.4px;
        color: '#1A2945';
    }

    .content2 {
        font-size: 13px;
        font-weight: 500;
        font-family: 'DIN bold';
        // line-height: 18.4px;
        color: '#222222';
    }

    .content3 {
        font-size: 12px;
        font-weight: 700;
        font-family: 'DIN bold';
        // line-height: 18.4px;
        color: '#444444';
    }

    .bold-text {
        font-weight: 700;
    }

    .content-row {
        flex-grow: 0;
        flex-shrink: 1;
        white-space: nowrap;
    }

    .footer {
        position: fixed;
        bottom: 0;
        width: 90%;
        padding: 10px;
    }

    .content-box {
        padding-right: 10px;
        margin-right: -12px;
        overflow-y: auto;
        overflow-x: hidden;
        box-sizing: content-box;
    }

    .content-container {
        display: flex;
        justify-content: space-between;
    }

    .predecessor-name {
        flex-grow: 1;
        font-weight: 400;
    }

    .style-one {
        text-align: right;
        white-space: nowrap;
    }

    .right-margin {
        margin-right: 5px;
    }

    .bold-text {
        font-weight: bold;
    }

    .flex-grow {
        flex-grow: 1;
    }

    .gray-color {
        color: #666666;
    }

}