@import "../../../styles/vars.scss";
@import "../../../styles/mixins.scss";

.add-user-container {
  @include flex-column-with-gap(32px);

  .title {
     font-size: 24px;
     font-weight: 700;
     line-height: 39.01px;
     color: $primary-heading;
  }

  .cancel-button {
     font-size: 16px;
     font-weight: 700;
     line-height: 32px;
     color: $red;
     margin-right: 10px;
  }
}