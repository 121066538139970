.app-dropdown-filter-wrapper {
   width: max-content;
   button.placeholder-button {
      background-color: #dedede;
      color: #444444;
      box-shadow: none;
      &:hover {
         box-shadow: none;
      }
   }
}
