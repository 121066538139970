@import "./styles/vars.scss";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  // font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
  //   "Droid Sans", "Helvetica Neue", sans-serif;
  font-family: 'DIN', 'DIN Bold', 'DIN Light','DIN Medium','DIN Bold Italic','DIN Medium Italic',
  'DIN Black';

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #F1F5F7;

  ::-ms-reveal {
    display: none;
}

/* Hide spinners for Chrome, Safari, Edge, and Opera */
input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Hide spinners for Firefox */
input[type=number] {
    -moz-appearance: textfield;
}


}

code {
  // font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

// styles for toast
.app-toast {
  .Toastify__toast--error {
    color: $red;
  }
}

@font-face {
  font-family: 'DIN';
  src: url('./assets/fonts/DIN_FONTS/DINOT.otf') ;
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Sofia Pro';
  src: url('./assets/fonts/DIN_FONTS/DINOT-Bold.otf');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'DIN Light';
  src: url('./assets/fonts/DIN_FONTS/DINOT-Light.otf');
  font-weight: lighter;
  font-style: normal;
}

@font-face {
  font-family: 'DIN Medium';
  src: url('./assets/fonts/DIN_FONTS/DINOT-Medium.otf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'DIN Black';
  src: url('./assets/fonts/DIN_FONTS/DINOT-Black.otf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'DIN Bold Italic';
  src: url('./assets/fonts/DIN_FONTS/DINOT-BoldItalic.otf');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'DIN Medium Italic';
  src: url('./assets/fonts/DIN_FONTS/DINOT-MediumItalic.otf') ;
  font-weight: normal;
  font-style: normal;
}

.css-99wn1p-MuiList-root-MuiMenu-list{
  width: 130px;
}
