@import "../../styles/vars.scss";
@import "../../styles/mixins.scss";

.sidebar-nav-content-container {
   flex: 1;
   display: flex;
   flex-direction: column;
   justify-content: space-between;

   .nav-list-wrapper {
      margin-top: 20px;
      @include flex-column-with-gap(8px);
      color: white;

      .selected-project-detail {
         color: $secondary;
         background-color: #435067;
         border-radius: 8px;
         padding: 8px;
      }

      .nav-item {
         cursor: pointer;
         text-decoration: none;
         color: inherit;

         &.active-link li {
            color: $secondary;
            background-color: #435067;
            border-radius: 8px;

            svg path {
               fill: $secondary;
            }
         }
      }
   }

   .user-name {
      font-size: 12px;
      font-weight: 400;
      line-height: 14.63px;
      color: white;
      text-overflow: ellipsis;
   }
   .user-email {
      font-size: 10px;
      font-weight: 400;
      line-height: 11.93px;
      color: white;
      text-overflow: ellipsis;
   }
}

.logout-confirm-modal-container {
   @include modal-centered-content-base-style;
   padding-top: 30px;
}
