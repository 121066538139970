@import "../../styles/vars.scss";
@import "../../styles/mixins.scss";

.dashboard-container {
   padding: 25px;
   width: 100%;
   height: 100%;
   @include flex-column;

   .header {
      display: flex;
      justify-content: space-between;

      .title {
         font-size: 24px;
         font-weight: 700;
         line-height: 29.26px;
         color: $primary;
      }
   }
   .welcome-content-container {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;

      .welcome-content {
         width: 730px;
      }

      .brand-name-wrapper {
         display: flex;
         gap: 8px;

         .brand-name {
            color: $primary;
            font-size: 80px;
            font-weight: 400;
            line-height: 95.44px;
         }

         .brand-name-filled {
            padding: 0px 24px 0px 24px;
            border-radius: 24px;
            background-color: white;
            display: flex;
            align-items: center;

            p {
               font-size: 64px;
               font-weight: 400;
               line-height: 78.02px;
               color: $secondary;
            }
         }
      }
      .description {
         color: $primary;
         width: 585px;
         font-size: 20px;
         line-height: 28px;
         margin-right: 25px;
      }
   }

   .user-dashboard-container {
      .project-count-section {
         margin-top: 35px;
         width: 100%;
         box-shadow: 0px 4px 8px 0px #0000001f;
         border-radius: 8px;
         background-color: white;
         @include flex-with-gap(10px);
         align-items: center;
         padding: 10px;

         .project-count {
            display: flex;
            width: 56px;
            height: 56px;
            border-radius: 6px;
            background-color: $primary;
            background: linear-gradient(172.88deg, #1b2944 0.68%, #4367aa 98.2%);
            justify-content: center;
            align-items: center;
            font-size: 32px;
            font-weight: 700;
            line-height: 40px;
            text-align: center;
            color: white;
         }
         .project-count-text {
            font-size: 18px;
            font-weight: 600;
            line-height: 21.94px;
            text-align: left;
            color: $primary;
         }
      }
      .project-list-section {
         margin-top: 40px;

         .project-card {
            padding: 12px;
            border-radius: 6px;
            // justify: space-between;
            background: white;
            box-shadow: 0px 4px 8px 0px #0000001f;
            @include flex-column-with-gap(10px);
            cursor: pointer;
            border: 2px solid white;
            transition: box-shadow 0.3s;
            &:hover {
               box-shadow: 0px 1px 2px 0px #4242421f;
            }

            &.selected {
               border: 2px solid #22bce7;
            }

            .project-name {
               font-size: 14px;
               font-weight: 600;
               line-height: 17.07px;
               color: $primary;
            }
            .project-role {
               font-size: 12px;
               font-weight: 600;
               line-height: 14.63px;
               color: $primary;
            }
         }
      }
   }
}

.import-xer-modal-container {
   @include modal-centered-content-base-style;
   padding-top: 30px;
   min-width: 400px;

   .title {
      font-size: 24px;
      font-weight: 700;
      line-height: 30px;
      color: $primary;
   }
}
