@import "../../../styles/vars.scss";
@import "../../../styles/mixins.scss";

.feedback-details-container {
//   @include flex-column-with-gap(32px);

  .title {
     font-size: 24px;
     font-weight: 700;
     line-height: 39.01px;
     font-family: 'DIN Bold' !important;
     color: $primary-heading;
  }
  .title2 {
    font-weight: 600;
    font-size: 14px;
    margin-top: 5px;
  }
  .marked-as-read {
     font-size: 16px;
     font-weight: 700;
     line-height: 32px;
     color: $green;
     margin-right: 10px;
  }

  .cancel-button {
     font-size: 16px;
     font-weight: 700;
     line-height: 32px;
     color: $red;
     margin-right: 10px;
  }
}