@import "../../styles/vars.scss";
@import "../../styles/mixins.scss";

.field-label {
   font-size: 16px;
   font-weight: 500;
   line-height: 20px;
   letter-spacing: -0.15px;
   color: $primary;
}

.error {
   color: $red;
}

.input-wrapper {
   width: 100%;
   height: 40px;
   display: flex;
   flex-direction: row;
   justify-content: flex-end;
}

.input {
   display: none;
   position: absolute
}
