@mixin flex-with-gap($gap) {
   display: flex;
   gap: $gap;
}

@mixin flex-column {
   display: flex;
   flex-direction: column;
}

@mixin flex-column-with-gap($gap) {
   @include flex-column;
   gap: $gap;
}

@mixin modal-centered-content-base-style {
   position: absolute;
   top: 50%;
   left: 50%;
   transform: translate(-50%, -50%);
   background-color: white;
   padding: 24px;
   border-radius: 30px;
   outline: none;
}

@mixin ellipsis {
   white-space: nowrap;
   overflow: hidden;
   text-overflow: ellipsis;
}