@import "../../../styles/vars.scss";
@import "../../../styles/mixins.scss";

.page-layout {
   display: flex;
   background-color: $page-bg;
   height: 100vh;

   .create-project-section {
      flex: 1;
      @include flex-column;
      overflow: auto;

      .heading-wrapper {
         margin: 25px;
         display: flex;
         justify-content: space-between;
         align-items: center;

         .title {
            font-size: 24px;
            line-height: 30px;
            font-weight: 700;
            color: $primary;
         }

         .subtitle {
            font-family: 'DIN';
            font-size: 14px;
            font-weight: 500;
            line-height: 24px;
            color: $primary;
         }
      }

      .project-create-form-container {
         margin: 0 25px 25px 25px;
         box-shadow: 0px 8px 16px 0px #0000001f;
         padding: 24px;
         border-radius: 12px;
         background-color: white;
         @include flex-column-with-gap(16px);

         .subtitle {
            font-size: 20px;
            font-weight: 600;
            line-height: 24.38px;
            color: $primary;
         }
      }
   }

   .update-project-section {
      flex: 1;
      @include flex-column;
      overflow: auto;

      .heading-wrapper {
         margin: 25px;
         display: flex;
         justify-content: space-between;
         align-items: center;

         .title {
            font-size: 24px;
            line-height: 30px;
            font-weight: 700;
            color: $primary;
         }

         .subtitle {
            font-family: 'DIN';
            font-size: 14px;
            font-weight: 500;
            line-height: 24px;
            color: $primary;
         }
      }

      .project-update-form-container {
         margin: 0 20px 25px 25px;
         box-shadow: 0px 8px 16px 0px #0000001f;
         padding: 24px;
         border-radius: 12px;
         background-color: "white";
         flex: 1;
         @include flex-column-with-gap(16px);

         .subtitle {
            font-size: 20px;
            font-weight: 600;
            line-height: 24.38px;
            color: $primary;
         }
      }

      .calendar-container {
         margin: 0 20px 25px 25px;
         box-shadow: 0px 8px 16px 0px #0000001f;
         border-radius: 12px;
         background-color: "white";
         padding: 24px;
         @include flex-column-with-gap(16px);

         .subtitle {
            font-size: 20px;
            font-weight: 600;
            line-height: 24.38px;
            color: $primary;
         }
      }

      .team-members-container {
         width: 280px;
         margin-right: 20px;

         .team-members-section {
            width: 100%;
            padding: 16px 12px;
            gap: 10px;
            border-radius: 12px;
            box-shadow: 0px 8px 16px 0px #0000001f;
            background-color: white;
            @include flex-column-with-gap(20px);

            .title {
               font-size: 20px;
               font-weight: 600;
               line-height: 24.38px;
               color: $primary;
            }

            .subtitle {
               font-family:
                  Helvetica Neue,
                  sans-serif;
               font-size: 16px;
               font-weight: 500;
               line-height: 20px;
               letter-spacing: -0.15px;
               color: $primary;
            }

            .no-members-section {
               @include flex-column-with-gap(16px);
               align-items: center;

               .no-members-text {
                  font-family: 'DIN';
                  font-size: 14px;
                  font-weight: 700;
                  line-height: 17.07px;
                  color: $primary;
               }

               .add-info-text {
                  font-size: 10px;
                  font-weight: 400;
                  line-height: 14px;
                  color: $primary;
               }
            }

            .user-name {
               font-size: 12px;
               font-weight: 400;
               line-height: 14.63px;
               color: $primary;
            }

            .user-role {
               font-size: 10px;
               font-weight: 400;
               line-height: 11.93px;
               color: $primary;
            }

            .expand-icon {
               cursor: pointer;
            }

            .add-member-action-text {
               font-family: 'DIN';
               font-size: 12px;
               font-weight: 700;
               line-height: 32px;
               color: $secondary;
               cursor: pointer;
               text-align: center;
            }
         }
      }
   }
}

.add-member-modal {
   @include modal-centered-content-base-style;
   @include flex-column-with-gap(32px);
   width: 462px;

   .modal-heading {
      @include flex-column-with-gap(16px);

      .title {
         font-family: 'DIN';
         font-size: 24px;
         font-weight: 700;
         line-height: 29.26px;
         color: $primary;
      }

      .subtitle {
         font-family:
            Helvetica Neue,
            sans-serif;
         font-size: 16px;
         font-weight: 400;
         line-height: 22.4px;
         color: $primary;
      }
   }

   .modal-fields {
      @include flex-column-with-gap(24px);
   }

   .modal-action-btns {
      @include flex-column-with-gap(16px);
   }
}