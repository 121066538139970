.error-boundary {
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   height: 100vh;
   background-color: #f8d7da;
   color: #721c24;
   text-align: center;
   padding: 20px;
   border: 1px solid #f5c6cb;
   border-radius: 8px;
 }
 
 .error-boundary h1 {
   font-size: 24px;
   margin-bottom: 10px;
 }
 
 .error-boundary p {
   margin-bottom: 20px;
 }
 
 .error-boundary button {
   margin: 5px;
   padding: 10px 15px;
   border: none;
   border-radius: 5px;
   cursor: pointer;
   font-size: 16px;
 }
 
 .error-boundary button:hover {
   background-color: #f5c6cb;
 }
 