@import "../../styles/vars.scss";
@import "../../styles/mixins.scss";

.typography-box {
    border: 1px solid #d9d9d9;
    border-radius: 6px;
    padding: 10px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 45px;
}