@import "../../../styles/vars.scss";
@import "../../../styles/mixins.scss";

.feedback-list-container {
   //  @include flex-column-with-gap(5px);
   font-family: 'DIN',!important;
   .title {
      font-size: 24px;
      font-weight: 700;
      font-family: 'DIN Bold Italic' !important;
      line-height: 39.01px;
      color: $primary-heading;
   }

   .title2 {
      color: #444444;
      font-weight: 600;
      font-size: 14px;
   }

   .cancel-button {
      font-size: 16px;
      font-weight: 700;
      line-height: 32px;
      color: $red;
      margin-right: 10px;
   }

   .status-filter-wrapper {
      margin-top: 10px !important;
      button {
         background-color: #dedede;
         color: #444444;
         box-shadow: none;
      }
   }
}
