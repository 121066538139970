@import "../../styles/vars.scss";
@import "../../styles/mixins.scss";

.sub-activity-form-container {
   width: 100%;
   padding: 0 24px;
   @include flex-column-with-gap(32px);

   form {
      @include flex-column-with-gap(12px);
   }

   .title {
      font-size: 20px;
      font-weight: 700;
      line-height: 39.01px;
      color: $primary;
   }

   .relation-text {
      font-size: 14px;
      font-weight: 700;
      line-height: 17px;
      color: $primary;
   }

   .work-day-wrapper {
      flex: 1;
      height: 45px;
      background-color: #e6e6e6;
      border-radius: 6px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      &.selected {
         background-color: $primary;
         p {
            color: white;
         }
      }

      p {
         color: #d0d0d0;
      }
   }

   .fields-container {
      @include flex-column-with-gap(24px);
      min-width: 415px;

      .remember-me-checkbox {
         font-weight: 500;
         font-size: 14px;
         line-height: 20px;
         letter-spacing: -0.15px;
         color: $primary;
      }
   }
}
