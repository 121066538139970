@import "../../styles/vars.scss";
@import "../../styles/mixins.scss";

.auth-layout {
   width: 100%;
   height: 100vh;
   min-height: 450px;
   overflow: hidden;
   display: flex;

   .leftside-section {
      width: 50%;
      height: 100%;
      position: relative;

      .auth-layout-banner {
         position: absolute;
         top: 0;
         left: 0;
         width: 100%;
         height: 100%;
         object-fit: cover;
         z-index: -2;
      }

      .overlay-effect {
         position: absolute;
         top: 0;
         left: 0;
         width: 100%;
         height: 100%;
         background-color: rgba($color: $primary, $alpha: 0.5);
         z-index: -1;
      }

      .dotted-bg-rect {
         position: absolute;
         left: 24px;
         bottom: 24px;
         z-index: -1;
      }

      .content-container {
         width: 100%;
         height: 100%;
         @include flex-column;
         justify-content: space-between;

         .logo-wrapper {
            margin: 40px 0 0 40px;
         }
         .content-wrapper {
            margin: 0 0 48px 24px;
            @include flex-column-with-gap(16px);

            .brand-name-wrapper {
               display: flex;
               gap: 8px;

               .brand-name {
                  color: white;
                  font-size: 80px;
                  font-weight: 400;
                  line-height: 95.44px;
               }

               .brand-name-filled {
                  padding: 0px 24px 0px 24px;
                  border-radius: 24px;
                  background-color: white;
                  display: flex;
                  align-items: center;

                  p {
                     font-size: 64px;
                     font-weight: 400;
                     line-height: 78.02px;
                     color: $secondary;
                  }
               }
            }
            .description {
               color: white;
               font-size: 20px;
               line-height: 28px;
               margin-right: 25px;
            }
         }
      }
   }
   .rightside-section {
      width: 50%;
      height: 100%;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;

      .bg-ellipsis {
         width: 400px;
         height: 400px;
         border-radius: 50%;
         opacity: 0.5;
         background: -moz-linear-gradient(283.51deg, #22bce7, #ffffff 85%);
         background: -webkit-linear-gradient(283.51deg, #22bce7, #ffffff 85%);
         background: linear-gradient(283.51deg, #22bce7, #ffffff 85%);
         position: absolute;
         bottom: -200px;
         right: -60px;
         z-index: -1;
      }

      .dotted-bg-square {
         position: absolute;
         top: 0;
         right: 20px;
         z-index: -1;
      }

      .auth-form-container {
         background-color: white;
         border-radius: 30px;
         box-shadow: 0px 8px 16px 0px #0000001f;
      }
   }
}
