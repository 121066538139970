
@import '../../styles/vars.scss';
@import '../../styles/mixins.scss';

.config-container {
//  @include flex-column-with-gap(5px);
  font-family: 'DIN',!important;
  .title {
     font-size: 14px;
     font-weight: 600;
     font-family: 'DIN Bold ' !important;
     line-height: 39.01px;
     color: $primary-heading;
  }

  .title2 {
    color: #444444;
    font-family: 'DIN Bold ' !important;
    font-weight: 600;
    font-size: 14px;
  }
}