@import "../../styles/vars.scss";
@import "../../styles/mixins.scss";

.reset-password-form-container {
	width: 470px;
	padding: 24px;
	max-height: calc(100vh - 60px);
	overflow: auto;
	@include flex-column-with-gap(32px);

	.form-title-container {
		@include flex-column-with-gap(16px);

		.title {
			font-size: 24px;
			font-weight: 700;
			line-height: 29.26px;
			color: $primary;
		}
		.description {
			font-size: 16px;
			font-weight: 400;
			line-height: 22.4px;
			color: $primary;
		}
		.instruction {
			font-size: 14px;
			font-weight: 400;
			line-height: 16.8px;
			color: $body-font;
		}
	}
	.form-fields-container {
		@include flex-column-with-gap(24px);
	}
	.form-button-container {
		@include flex-column-with-gap(24px);
	}
	.logo-wrapper {
		margin: 50px 0 0 50px;
	}
}
