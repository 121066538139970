@import "../../styles/vars.scss";
@import "../../styles/mixins.scss";

.login-form-container {
   width: 495px;
   padding: 40px;
   @include flex-column-with-gap(32px);

   .title {
      font-size: 32px;
      font-weight: 700;
      line-height: 39.01px;
      text-align: center;
      color: $primary;
   }

   .fields-container {
      @include flex-column-with-gap(24px);
      min-width: 415px;

      .remember-me-checkbox {
         font-weight: 500;
         font-size: 14px;
         line-height: 20px;
         letter-spacing: -0.15px;
         color: $primary;
      }
   }
}
