@import "../../../styles/vars.scss";
@import "../../../styles/mixins.scss";

.modal-container {
   position: absolute;
   top: 50%;
   left: 50%;
   transform: translate(-50%, -50%);
   background-color: white;
   padding: 24px;
   border-radius: 30px;

   .header-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
   }

   .title {
      font-size: 24px;
      font-weight: 700;
      color: $red;
      margin-left: 10px;
   }

   .icon {
      width: 25px;
      height: 25px;
      color: $red;
      cursor: pointer;
   }

   .sub-title {
      font-size: 16px;
      line-height: 20px;
      font-weight: 400;
      color: $primary;
      margin-top: 10px;
   }

   .button-wrapper {
      flex-direction: column;
      display: flex;
      align-items: center;
   }

   .deactivate-button {
      background-color: $red;
      color: white;
      border: none;
      border-radius: 30px;
      padding: 8px 16px;
      cursor: pointer;
      margin-top: 20px;
      margin-bottom: 15px;
   }

   .close-button {
      font-size: 16px;
      font-weight: 700;
      color: $primary;
      cursor: pointer;
   }
}
