@import "../../styles/vars.scss";
@import "../../styles/mixins.scss";

.password-form-container {
   width: 462px;
   padding: 24px;
   @include flex-column-with-gap(32px);

   .title {
      font-size: 32px;
      font-weight: 700;
      line-height: 39.01px;
      // text-align: center;
      color: $primary-heading;
   }

    .heading1 {
        font-size: 16px;
        font-weight: 400;
        line-height: 16px;
        margin-top: 20px;
        // text-align: center;
        color: $primary;
    }

    .heading2 {
      font-size: 16px;
      font-weight: 400;
      line-height: 14px;
      margin-top: 20px;
      // text-align: center;
      color: $grey;
  }

   .fields-container {
      @include flex-column-with-gap(24px);
      min-width: 415px;
      margin-top: 20px;

      .remember-me-checkbox {
         font-weight: 500;
         font-size: 14px;
         line-height: 20px;
         letter-spacing: -0.15px;
         color: $primary;
      }
   }
   
   .cancel-button {
      font-size: 16px;
      font-weight: 700;
      line-height: 32px;
      color: $red;
   }
}
