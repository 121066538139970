@import "../../styles/vars.scss";
@import "../../styles/mixins.scss";

.heading-wrapper {
  margin: 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .title {
     font-size: 24px;
     line-height: 30px;
     font-weight: 700;
     color: $primary;
     width: 320px;
     word-wrap: break-word;
  }
  .subtitle {
     font-family: 'DIN';
     font-size: 14px;
     font-weight: 500;
     line-height: 24px;
     color: $primary;
     width: 320px;
     word-wrap: break-word;
  }
}