// colors
$page-bg: #f1f5f7;
$primary: #1a2945;
$secondary: #22bce7;
$primary-heading: #1B2944;
$interaction: #222222;
$red: #e72222;
$body-font: #444444;
$green: #54ae2a;
$grey: #444444;
$grey-light: #42424280;
$grey2: #42424280;

$swimlane: (
    delayed: rgb(231, 92, 92),
    early: rgb(89, 188, 124),
    weekend: rgba(220, 118, 118, 0.05),
    calender: #215B90,
    electrician: #F57F32,
    painting: #01A79E,
    expand: #7785CC,
);

@function swimlane($name) {
    @return map-get($swimlane, $name);
}