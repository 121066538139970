
@import '../../styles/vars.scss';
@import '../../styles/mixins.scss';


.pagination-container {
    height: 56px;
    background: #22bce714;
    padding: 12px 24px;
    display: flex;
    justify-content: space-between;
 }