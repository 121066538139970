@import "../../styles/vars.scss";
@import "../../styles/mixins.scss";

.profile-setup-form-container {
   width: 495px;
   padding: 40px;
   max-height: calc(100vh - 60px);
   overflow: hidden;
   @include flex-column-with-gap(32px);

   .form-title-wrapper {
      flex: 1;
      @include flex-column-with-gap(16px);

      .form-title {
         font-size: 24px;
         font-weight: 700;
         line-height: 29.26px;
         color: $primary;
      }

      .form-description {
         font-size: 16px;
         font-weight: 400;
         line-height: 22.4px;
         color: $primary;
      }
   }
   .form-sub-section {
      flex: 1;
      overflow-y: auto;
      @include flex-column-with-gap(20px);

      .form-subtitle {
         font-size: 18px;
         font-weight: 700;
         line-height: 21.94px;
         color: $primary;
      }

      .form-fields-section {
         @include flex-column-with-gap(24px);
      }
   }
   .action-btn-wrapper {
      flex: 1;
      display: flex;
      justify-content: flex-end;
   }
}
