
@import '../../styles/vars.scss';
@import '../../styles/mixins.scss';

.AppBar-container {
//  @include flex-column-with-gap(5px);

  .title {
     font-size: 24px;
     font-weight: 700;
     line-height: 39.01px;
     color: $primary-heading;
  }

  .title2 {
    color: #444444;
    font-weight: 600;
    font-size: 14px;
  }

  .cancel-button {
     font-size: 16px;
     font-weight: 700;
     line-height: 32px;
     color: $red;
     margin-right: 10px;
  }
}