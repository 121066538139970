@import '../../styles/vars.scss';
@import '../../styles/mixins.scss';


.swimlane-calender-container {
  max-width: calc(100vw - 300px);
  max-height: calc(100vh - 170px);
  position: relative;
  display: flex;
  flex-direction: column;

  .react-calendar-timeline {
    &:first-child {
      position: sticky;
      top: 0;
      z-index: 100;

      .rct-outer {
        display: none;
      }
    }
  }

  .timeline-scrollable {
    flex-grow: 1;
    overflow-y: auto;
    position: relative;
  }

  .expand {
    background-color: swimlane(expand);
    padding: 4px;
    display: flex;
    align-items: center;
    border-top: 4px solid white;
    cursor: pointer;
    z-index: 20;

    >svg {
      height: 12px;
      width: 12px;
      margin-left: 8px;
    }
  }
}





// .rct-vertical-lines .rct-vl {
//   display: none;
// }
.rct-vertical-lines {
  z-index: -1;
  position: relative;
}

.rct-header-root,
.rct-calendar-header {
  border: none;
}

.rct-item {
  border: none;
  min-width: 40px;
}

.react-calendar-timeline .rct-vertical-lines .rct-vl.rct-day-6,
.react-calendar-timeline .rct-vertical-lines .rct-vl.rct-day-0 {
  background-color: swimlane(weekend);
}


.react-calendar-timeline .rct-vertical-lines .rct-vl {
  // border-left-width: 0px;
}

.rct-horizontal-lines .rct-hl {
  display: none;
}

// .react-calendar-timeline .rct-horizontal-lines .rct-hl-odd, .react-calendar-timeline .rct-sidebar .rct-sidebar-row.rct-sidebar-row-odd {
//   background: unset;
// }
.react-calendar-timeline .rct-horizontal-lines .rct-hl-odd,
.react-calendar-timeline .rct-sidebar .rct-sidebar-row.rct-sidebar-row-odd {
  background: unset;
}

.react-calendar-timeline .rct-horizontal-lines .rct-hl-even,
.react-calendar-timeline .rct-horizontal-lines .rct-hl-odd,
.react-calendar-timeline .rct-sidebar .rct-sidebar-row {
  // border-bottom: 0;
}

// /* Remove alternate grey rows */
// .rct-calendar-header .rct-calendar-header-row:nth-child(odd) {
//   background-color: transparent;
// }
.rct-calendar-header,
.rct-header-root {
  border-radius: 12px 12px 0 0;
}

.rct-calendar-header .rct-calendar-header-row {
  border-bottom: none;
}


.rct-header-root>div:nth-child(1),
.react-calendar-timeline .rct-dateHeader,
.react-calendar-timeline .rct-dateHeader {
  background-color: swimlane(calender);
  border-left: unset;
}

.react-calendar-timeline .rct-dateHeader-primary,
.react-calendar-timeline .rct-dateHeader {
  border-right: unset;
  border-bottom: unset;
}