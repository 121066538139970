
@import '../../styles/vars.scss';
@import '../../styles/mixins.scss';

.profile-user-container {
//  @include flex-column-with-gap(5px);
font-family: 'DIN ' !important;

  .title {
     font-size: 24px;
     font-weight: 700;
     line-height: 39.01px;
     color: $primary-heading;
  }

  .title2 {
    margin-bottom: 10px !important;
    color: $primary-heading;
    font-weight: 600;
    font-size: 16px;
  }

  .cancel-button {
     font-size: 16px;
     font-weight: 700;
     line-height: 32px;
     color: $red;
     margin-right: 10px;
  }
}