@import "../../styles/vars.scss";
@import "../../styles/mixins.scss";

.field-wrapper {
   @include flex-column-with-gap(6px);

   .field-label {
      font-size: 16px;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: -0.15px;
      color: $primary;

      &.error {
         color: $red;
      }
      &.secondary {
         color: $secondary;
      }
   }
}
