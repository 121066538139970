@import "../../styles/vars.scss";
@import "../../styles/mixins.scss";

.calendar-data-container {
    margin-top: 5px;
    display: flex;
    flex-wrap: wrap;
    gap: 6px;
}

.calendar-day-box {
    padding: 10px 10px;
    border-radius: 6px;
    font-size: 13px;
    color: #D0D0D0;
    text-align: center;
    align-items: center;
    justify-content: center;
    font-weight: 400;
    flex: 1;
    box-sizing: border-box;

    &.present {
        background-color: $primary;
    }

    &.absent {
        background-color: #E6E6E6;
    }
}