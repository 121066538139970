@import "../../styles/vars.scss";
@import "../../styles/mixins.scss";

.log-list-container {
   flex: 1;
   @include flex-column;

   .search-and-filters-container {
      margin: 0 25px;
      @include flex-with-gap(24px);
      align-items: center;

   }

   .list-table-container {
      margin: 10px 25px;
   }
}
