

@import "../../styles/vars.scss";
@import "../../styles/mixins.scss";


.session-details-modal {
   padding: 16px;
   background-color: #f5f5f5;

   .title{
      font-size: 12px;
      font-weight: 600;
      font-family: 'DIN';
      line-height: 29.26px;
      color: '#1A2945'; 
  }
  .title2{
   font-size: 12px;
   font-weight: 500;
   font-family: 'DIN';
   line-height: 29.26px;
   color: '#7D7D7D'; 
   margin: 16px 0 16px 0; 
 
}
.no-data{
   font-size: 20px;
   font-weight: 700;
   font-family: 'DIN';
   line-height: 29.26px;
   color: '#1A2945';

}
 }
 
 .table-header {
   .MuiTableCell-root {
      width: 100%;
      // padding: "12px";
      color: "#ffffff";
      font-weight: "600";
      font-size: "14px";
      background-color: "#1B2944";
      text-align: "center",
   }
 }
 
 .MuiTableCell-root {
   font-family: "Roboto", sans-serif;
   font-size: 14px;
 }
 
