@import '../../styles/vars.scss';
@import '../../styles/mixins.scss';

@mixin padding {
    padding: 0 16px 0 16px;
}

.card-container {
    position: relative;
    background-color: "transparent";
    border-radius: 4px;
    // box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    // padding-top: 2px;
    width: 100%;
    font-weight: 200;

    &.delayed {
        border-left: 8px solid swimlane(delayed);
    }

    &.early {
        border-left: 8px solid swimlane(early);
    }

    .card {
        background-color: white;
        align-items: center;
        border-radius: 8px;
    }

    .card-title,
    .card-subtitle,
    .card-type {
        @include padding;
        @include ellipsis;
    }

    .card-title {
        color: $primary-heading;
        font-size: 12px;
    }

    .card-subtitle {
        color: $grey;
        cursor: pointer;
    }

    .card-type {
        color: white;
        border-radius: 0 0 4px 4px;
        background-color: green;
    }

    .float-text {
        color: red;
        font-size: 12px;
    }
}