@import "../../styles/vars.scss";
@import "../../styles/mixins.scss";

.list-table-container {
  //  @include flex-column-with-gap(5px);
  // margin: 10px 25px;
  flex: 1;
  overflow-y: auto;
  display: flex;
  table {
    thead {
      th {
        background-color: $page-bg;
      }
    }
    tbody {
      tr {
        td {
          background-color: white;
          line-height: 24px;
        }
        td:first-child {
          border-top-left-radius: 6px;
          border-bottom-left-radius: 6px;
        }
        td:last-child {
          border-top-right-radius: 6px;
          border-bottom-right-radius: 6px;
        }
      }
    }
  }
  .title {
    font-size: 24px;
    font-weight: 700;
    line-height: 39.01px;
    color: $primary-heading;
  }

  .title2 {
    color: #444444;
    font-weight: 600;
    font-size: 14px;
  }

  .cancel-button {
    font-size: 16px;
    font-weight: 700;
    line-height: 32px;
    color: $red;
    margin-right: 10px;
  }
}
.pagination-container {
  height: 56px;
  background: #22bce714;
  padding: 12px 24px;
  display: flex;
  justify-content: space-between;
}
