@import "../../styles/vars.scss";
@import "../../styles/mixins.scss";

.logs-container {
  //  @include flex-column-with-gap(5px);
  background-color: #f1f5f7 !important;
  flex: 1;

  .title {
    font-size: 24px;
    font-weight: 700;
    line-height: 39.01px;
    color: $primary-heading;
  }

  .title2 {
    margin-bottom: 10px !important;
    color: #22bce7;
    font-weight: 600;
    font-size: 16px;
  }

  .search-label {
    font-family: 'DIN Light' !important;
    margin-bottom: 10px !important;
    font-weight: 600;
    font-size: 16px;
  }

  .cancel-button {
    font-size: 16px;
    font-weight: 700;
    line-height: 32px;
    color: $red;
    margin-right: 10px;
  }

  .title-width{
    margin-bottom: 10px !important;
    color: #22bce7;
    font-weight: 600;
    font-size: 16px;
    width: 32%   
  }
  @media (width >= 1400px) {
    .title-width{
      width: 31.2% ;
    }
  }
}