button.app-button {
   border-radius: 100px;
   padding: 0 16px;

   &.small {
      height: 32px;
      font-size: 14px;
      font-weight: 500;
      line-height: 32px;
   }

   &.medium {
      height: 40px;
      font-size: 14px;
      font-weight: 500;
      line-height: 32px;
   }

   &.large {
      height: 56px;
      font-size: 16px;
      font-weight: 700;
      line-height: 32px;
   }
}
