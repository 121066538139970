@import "../../styles/vars.scss";
@import "../../styles/mixins.scss";

.company-container {
   width: 462px;
   padding: 24px;
   @include flex-column-with-gap(32px);

   .title {
      font-size: 32px;
      font-weight: 700;
      line-height: 39.01px;
      // text-align: center;
      color: $primary-heading;
   }

   .heading1 {
      font-size: 16px;
      font-weight: 400;
      line-height: 16px;
      margin-top: 20px;
      // text-align: center;
      color: $primary;
   }

   .heading2 {
      font-size: 16px;
      font-weight: 400;
      line-height: 14px;
      margin-top: 20px;
      // text-align: center;
      color: $grey;
   }

   .fields-container {
      @include flex-column-with-gap(24px);
      min-width: 415px;
      margin-top: 20px;
   }
   
   .cancel-button {
      font-size: 16px;
      font-weight: 700;
      line-height: 32px;
      color: $red;
      margin-right: 10px;
   }
}

.page-layout {
   display: flex;
   background-color: $page-bg;
   height: 100vh;

   .users-list-section {
      flex: 1;
      @include flex-column;

      .heading-wrapper {
         margin: 25px;
         display: flex;
         justify-content: space-between;
         align-items: center;

         .title {
            font-size: 24px;
            line-height: 30px;
            font-weight: 700;
            color: $primary;
         }
      }

      .search-and-filters-container {
         margin: 0 25px;
         display: flex;
         justify-content: space-between;
         align-items: center;

         .search-and-filters {
            @include flex-with-gap(24px);
         }
         .status-filter-wrapper {
            button {
               background-color: #dedede;
               color: #444444;
               box-shadow: none;
            }
         }
         .tabs-wrapper {
            padding: 4px;
            background: $primary;
            @include flex-with-gap(4px);
            border-radius: 6px;
            .tab {
               padding: 4px 8px;
               font-size: 14px;
               line-height: 16px;
               border-radius: 4px;
               color: white;
               cursor: pointer;

               &.selected {
                  background-color: white;
                  color: $primary;
               }
            }
         }
      }

      

      .list-table-container {
         margin: 10px 25px;
      }
   }
}

.add-company-container {
   @include flex-column-with-gap(32px);

   .title {
      font-size: 24px;
      font-weight: 700;
      line-height: 39.01px;
      color: $primary-heading;
   }

   .cancel-button {
      font-size: 16px;
      font-weight: 700;
      line-height: 32px;
      color: $red;
      margin-right: 10px;
   }
 }

 .detail-container {
   width: 100%;

   .title {
      font-size: 24px;
      font-weight: 700;
      line-height: 39.01px;
      color: $primary-heading;
   }


   .field-wrapper {
      margin-top: 10px;
   }

   .dotted-border {
      // border: 1px dashed $grey2;
      border: 1px solid $grey2;
      border-radius: 6px;
      padding: 16px;
      border-color: $grey2;
      width: 80%;
      height: 80%;
      background-color: $red
   }

   .image {
      width: 100%;
      height: 100%;
      border-radius: 8px;
   }
 }